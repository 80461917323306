import React from 'react'

import Layout from 'components/Layout/Layout';
import CreditCheck from 'components/CreditCheck/CreditCheck';
import SEO from 'components/seo';

const CreditCheckPage = () => (
  <Layout>
    <SEO title="Credit Check" />
    <CreditCheck />
  </Layout>
)

export default CreditCheckPage
