import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { navigate } from 'gatsby';
import { creditAdjudication, setConsentDatetime } from 'actions/actions';

import Form from 'components/Common/Form/Form';
import Header from 'components/Common/Layout/Header';
import Radio from 'components/Common/Form/Radio';
import CheckboxGroup from 'components/Common/Form/CheckboxGroup';
import Navigation from 'components/Common/Layout/Navigation';
import Disclaimer from 'components/Common/Layout/Disclaimer';

export default React.memo(() => {
  const dispatch = useDispatch();
  const { cifId, applicationId, consentDatetime, accessToken, isMobile } = useSelector(state => state.reducer);
  const { creditCheck } = useSelector(state => state.reducer.requirements);
  const [state, setState] = useState({
    creditCheck: creditCheck || null
  });

  const handleChange = (event) => {
    let { name, value } = event.target;
    dispatch(setConsentDatetime({
      cifId: cifId,
      applicationId: applicationId
    }, accessToken, isMobile));   
    setState(prevState => ({ ...prevState, [name]: value }));
  }

  const submit = (e) => { 
    e.preventDefault();
    if(canContinue) {
      dispatch(creditAdjudication({
        cifId: cifId,
        applicationId: applicationId,
        creditBureauConsent: state.creditCheck === 'true' ? consentDatetime : null,
      }, accessToken, isMobile));   
    }
  }

  const canContinue = (state.creditCheck && consentDatetime);

  return (
    <Form>
      <Header>We need to check your credit</Header>
      <CheckboxGroup label="Do you consent to a credit check?">        
        <p>We need to check your credit history to continue your loan application. Your credit history will help us determine your eligibility for a loan.</p>
        <p>Getting your credit history checked can sometimes lower your credit score.</p>
        <p>If you have agreed to a credit check for one of our online lending products within the last 90 days, we will not check your credit history again now.</p>
        <div>
          <Radio name="creditCheck" value="true" label="Yes, I consent to a credit check" onChange={handleChange} selectedValue={state.creditCheck} />
          <Radio name="creditCheck" value="false" label="No, I do not consent to a credit check and would like to cancel my loan application" onChange={handleChange} selectedValue={state.creditCheck} />
        </div>
      </CheckboxGroup>
      <Navigation buttonLabel="Confirm" onClickForward={submit} disabled={!canContinue} />
      <Disclaimer>        
        <p>For lending products and our decision as to when to give you access to funds is a credit decision, we will obtain information and reports about you from credit reporting agencies, other financial institutions and other lenders during the application process and on ongoing basis to review and verify your creditworthiness and establish credit limits.</p>
        <p>Once you have such a facility or product with us, we may from time to time disclose your information to other financial institutions, other lenders and credit reporting agencies seeking such information, which helps establish your credit history and supports the credit granting process.</p>
      </Disclaimer>
    </Form>);
});